@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,900;1,900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css");
td {
  vertical-align: middle !important;
}
.table-danger {
  background-color: #EDDDDD !important;
}
body{
  font-family: Roboto;
}
.topBar {
  width:100%;
  overflow-y: hidden;
  overflow-x: scroll;
  height:65px;
  border-bottom: solid 1px #D3D3D3;
  padding-left: 280px;
}
.topBar h1 {
  font-weight: 900 !important;
  width: max-content;
  line-height: 60px;
}
.pull-right
{
  float:right;
}
.logo {
  width: 150px;
  padding:5px;
}
.wrapper {
  position: relative;
  overflow: scroll;
  width:100%;
  height: 100vh
}
.contentWrapper{
  position: absolute;
  width:100%;
  padding-left:265px;
  padding-right: 15px;
  float: left;
}

.form-backdrop {
  background-color: #8a8a8a;
}

.form-card {
  /* border: 1px solid #e0e0e0; */
  padding: 15px;
  border-radius: 10px;
}
.loginTitle {
  text-align: center;
  font-weight: 900;
  font-style: italic;
  padding:15px 0px;
  font-size: 65px;
}
.loginTitle img {
  width:200px;
  padding: 20px;
}
.loginBox {
  width:500px;
  margin:auto;
  border: 1px solid #e0e0e0;
  padding:15px;
  border-radius:10px;
}
.styleLink{
  color:darkorange;
  text-decoration: none;
}
.styleLink:hover{
  text-decoration: none;
  color:lightsalmon;
}

.nav {
  margin-bottom: 15px;
}
.nav-tabs {
  border-bottom:2px solid rgba(0, 0, 0, .2);
  padding-bottom:15px;
}
.nav-link {
  border:none !important;
  background-color: transparent !important;
  color:gray;
}
.nav-link:hover {
  color:gray !important;
}
.nav-link.active {
  background-color: rgba(0, 0, 0, .05) !important;
  border-radius: 5px !important;
  color:black !important;
  font-weight: 400 !important;
}
.card-hover {
  transition:.3s;
}
.card-hover:hover{
  cursor:pointer;
  transform: scale(1.1);
}

@media (max-width:1024px) {

  .topBar h1 {
    font-size: 25px;
  }
  .contentWrapper {
    margin-left:0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .topBar {
    padding-left: 40px;
  }
  
  .loginBox
  {
    width:95%;
  }
}
@media (max-width:768px)
{
  
}
@media (max-width:425px)
{
  .topBar h1 {
    font-size: 15px;
  }
}
