.sidebar {
    transition: all .2s;
    position: fixed;
    float: left;
    margin-top: 0;
    width:10%;
    min-width: 250px;
    min-height: 100vh;
    height: 100vh;
    background-color: #252525;
    z-index: 100;
}
.sidebarBlocker {
    display: none;
    position: fixed;
    width:100%;
    height:100%;
    left:0;
    z-index: 99;
}

.sidebar h2 {
    text-align: center;
    margin: 0;
    padding:0;
    font-style: italic;
    font-weight: 900;
}
.sidebar ul {
    margin:0;
    padding:0;
}
.sidebar ul li a {
    display: block;
    padding:15px;
    text-decoration: none;
    color:#7C7C7C;
}
.sidebar ul li a:hover {
    color: #FFFFFF;
    margin-left:-2px;
}
.sidebar ul li a:hover span {
    border-left: solid #FFFFFF 2px;
    position: relative;
    padding: 0;
    left: -12px;
    margin: 0;
}
.sidebar ul li a.active {
    color: #FFFFFF;
    margin-left:-2px;
}
.sidebar ul li a.active span {
    border-left: solid #FFFFFF 2px;
    position: relative;
    padding: 0;
    left: -12px;
    margin: 0;
}
.menu-button {
    display:none;
    transition: all .2s;
    background-color: white;
    line-height: 60px;
    padding: 0 5px;
    z-index: 99;
    left:0;
    position: absolute;
}
.menu-button.open{
    left:250px !important;
}
.menu-button button {
    padding:0px 0 0 1px !important;
    font-size: 25px;
    border: none;
    color:black;
    background-color: transparent;
    outline: none;
}
.menu-button button:hover {
    color:orange
}

.logoutBtn {
    position: absolute;
    font-weight: 900;
    color: #A4A4A4;
    width:100%;
    padding:15px;
    text-align: left;
    bottom:10px;
}
.logoutBtn:hover {
    color: white;
    text-decoration: none;
}
.logoutBtn span {
    margin-left: 15px;;
}

@media (max-width:1024px) {
    .menu-button {
        display:block;
        float: left;
    }
    .sidebarBlocker.open {
        display:block;
    }
    .sidebar{
      left:-250px;
      z-index: 100;
    }
    .sidebar.open {
      left:0px !important;
    }
  }