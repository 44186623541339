.timer {
    display: inline-flex;
    width:100%;
    height: 50px;
}
.timer input {
    flex:1;
    padding: 10px;
    flex-basis: 50%;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    margin-right: 5px;
}
.timer input:disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);;
}
.timer .timerSelect {
    flex-basis: 50%;
    margin-left: 5px;
}
.timer .timerSelect .timerSelect__control {
    min-height: 50px;
}
.timerSelect__menu {
    z-index: 9999 !important;
}
.timer p {
    flex-basis: 200px;
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    user-select: none;
    line-height: 50px;
    vertical-align: middle;
}
.timer button {
    min-width:50px;
    min-height:50px;
    line-height: 50px;
    vertical-align: middle;
    background-color:transparent;
    border:none;
    border-radius: 100%;
    color:white;
}
.timer button.play {
    background-color: #00C851;
}
.timer button.play:hover {
    background-color: #007E33;
}
.timer button.stop {
    background-color: #ff4444;
}
.timer button.stop:hover {
    background-color: #CC0000;
}
@media (max-width:768px)
{
    .timer {
        flex-wrap: wrap;
    }
    .timer input {
        margin-bottom:10px;
    }
    .timer input, .timerSelect {
        margin-left:0 !important;
        margin-right:0 !important;
    }
    .timer .timerSelect, .timer p {
        margin-top:5px;
        flex-basis: 100%;
    }
    .timer button {
        margin: auto;
    }
}