@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,900;1,900&display=swap);
@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css);



td {
  vertical-align: middle !important;
}
.table-danger {
  background-color: #EDDDDD !important;
}
body{
  font-family: Roboto;
}
.topBar {
  width:100%;
  overflow-y: hidden;
  overflow-x: scroll;
  height:65px;
  border-bottom: solid 1px #D3D3D3;
  padding-left: 280px;
}
.topBar h1 {
  font-weight: 900 !important;
  width: -webkit-max-content;
  width: max-content;
  line-height: 60px;
}
.pull-right
{
  float:right;
}
.logo {
  width: 150px;
  padding:5px;
}
.wrapper {
  position: relative;
  overflow: scroll;
  width:100%;
  height: 100vh
}
.contentWrapper{
  position: absolute;
  width:100%;
  padding-left:265px;
  padding-right: 15px;
  float: left;
}

.form-backdrop {
  background-color: #8a8a8a;
}

.form-card {
  /* border: 1px solid #e0e0e0; */
  padding: 15px;
  border-radius: 10px;
}
.loginTitle {
  text-align: center;
  font-weight: 900;
  font-style: italic;
  padding:15px 0px;
  font-size: 65px;
}
.loginTitle img {
  width:200px;
  padding: 20px;
}
.loginBox {
  width:500px;
  margin:auto;
  border: 1px solid #e0e0e0;
  padding:15px;
  border-radius:10px;
}
.styleLink{
  color:darkorange;
  text-decoration: none;
}
.styleLink:hover{
  text-decoration: none;
  color:lightsalmon;
}

.nav {
  margin-bottom: 15px;
}
.nav-tabs {
  border-bottom:2px solid rgba(0, 0, 0, .2);
  padding-bottom:15px;
}
.nav-link {
  border:none !important;
  background-color: transparent !important;
  color:gray;
}
.nav-link:hover {
  color:gray !important;
}
.nav-link.active {
  background-color: rgba(0, 0, 0, .05) !important;
  border-radius: 5px !important;
  color:black !important;
  font-weight: 400 !important;
}
.card-hover {
  transition:.3s;
}
.card-hover:hover{
  cursor:pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media (max-width:1024px) {

  .topBar h1 {
    font-size: 25px;
  }
  .contentWrapper {
    margin-left:0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .topBar {
    padding-left: 40px;
  }
  
  .loginBox
  {
    width:95%;
  }
}
@media (max-width:768px)
{
  
}
@media (max-width:425px)
{
  .topBar h1 {
    font-size: 15px;
  }
}

.timer {
    display: inline-flex;
    width:100%;
    height: 50px;
}
.timer input {
    flex:1 1;
    padding: 10px;
    flex-basis: 50%;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    margin-right: 5px;
}
.timer input:disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);;
}
.timer .timerSelect {
    flex-basis: 50%;
    margin-left: 5px;
}
.timer .timerSelect .timerSelect__control {
    min-height: 50px;
}
.timerSelect__menu {
    z-index: 9999 !important;
}
.timer p {
    flex-basis: 200px;
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    line-height: 50px;
    vertical-align: middle;
}
.timer button {
    min-width:50px;
    min-height:50px;
    line-height: 50px;
    vertical-align: middle;
    background-color:transparent;
    border:none;
    border-radius: 100%;
    color:white;
}
.timer button.play {
    background-color: #00C851;
}
.timer button.play:hover {
    background-color: #007E33;
}
.timer button.stop {
    background-color: #ff4444;
}
.timer button.stop:hover {
    background-color: #CC0000;
}
@media (max-width:768px)
{
    .timer {
        flex-wrap: wrap;
    }
    .timer input {
        margin-bottom:10px;
    }
    .timer input, .timerSelect {
        margin-left:0 !important;
        margin-right:0 !important;
    }
    .timer .timerSelect, .timer p {
        margin-top:5px;
        flex-basis: 100%;
    }
    .timer button {
        margin: auto;
    }
}
@media (max-width:768px)
{
    .extraMargin {
        margin-top:210px !important;
    }
}
.sidebar {
    transition: all .2s;
    position: fixed;
    float: left;
    margin-top: 0;
    width:10%;
    min-width: 250px;
    min-height: 100vh;
    height: 100vh;
    background-color: #252525;
    z-index: 100;
}
.sidebarBlocker {
    display: none;
    position: fixed;
    width:100%;
    height:100%;
    left:0;
    z-index: 99;
}

.sidebar h2 {
    text-align: center;
    margin: 0;
    padding:0;
    font-style: italic;
    font-weight: 900;
}
.sidebar ul {
    margin:0;
    padding:0;
}
.sidebar ul li a {
    display: block;
    padding:15px;
    text-decoration: none;
    color:#7C7C7C;
}
.sidebar ul li a:hover {
    color: #FFFFFF;
    margin-left:-2px;
}
.sidebar ul li a:hover span {
    border-left: solid #FFFFFF 2px;
    position: relative;
    padding: 0;
    left: -12px;
    margin: 0;
}
.sidebar ul li a.active {
    color: #FFFFFF;
    margin-left:-2px;
}
.sidebar ul li a.active span {
    border-left: solid #FFFFFF 2px;
    position: relative;
    padding: 0;
    left: -12px;
    margin: 0;
}
.menu-button {
    display:none;
    transition: all .2s;
    background-color: white;
    line-height: 60px;
    padding: 0 5px;
    z-index: 99;
    left:0;
    position: absolute;
}
.menu-button.open{
    left:250px !important;
}
.menu-button button {
    padding:0px 0 0 1px !important;
    font-size: 25px;
    border: none;
    color:black;
    background-color: transparent;
    outline: none;
}
.menu-button button:hover {
    color:orange
}

.logoutBtn {
    position: absolute;
    font-weight: 900;
    color: #A4A4A4;
    width:100%;
    padding:15px;
    text-align: left;
    bottom:10px;
}
.logoutBtn:hover {
    color: white;
    text-decoration: none;
}
.logoutBtn span {
    margin-left: 15px;;
}

@media (max-width:1024px) {
    .menu-button {
        display:block;
        float: left;
    }
    .sidebarBlocker.open {
        display:block;
    }
    .sidebar{
      left:-250px;
      z-index: 100;
    }
    .sidebar.open {
      left:0px !important;
    }
  }
